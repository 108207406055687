import { useMemo } from "react";
import { Size, useWindowSize } from "~/utils/hooks/tailwindcss";
// import twConfig from "../../../tailwind.config.extend";
import { createContext, useContext, useContextSelector } from "use-context-selector";

const screens = {
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
  "2xl": "1536px",
};

const PagesizeContext = createContext({});
/**
 * A hook that will return inner and outer height and width values whenever
 * the window is resized.
 *
 * @kind function
 * @private
 */
const usePagesizeContextVals = () => {
  const size: Size = useWindowSize();
  const sizes = useMemo(() => {
    const test = Object.keys(screens).reduce((acc, key) => {
      const value = screens[key];
      const breakSize = parseInt(value.replace("px", ""));
      acc[key] = size.width >= breakSize;
      return acc;
    }, {});
    return test;
  }, [size.width]);

  // const sizes = useMemo(() => {
  //   //when the width changes, update the sizes
  //   return Object.keys(theme.theme.extend.screens).reduce((acc, key) => {
  //     const value = theme.theme.extend.screens[key];
  //     const size = value.replace("px", "");
  //     acc[key] = size.width >= size;
  //     return acc;
  //   }, {});
  // }, [size.width]);

  return {
    width: size.width,
    height: size.height,
    sizes,
  };
};

const PagesizeContextProvider = (props) => {
  // This hook has side effects of adding listeners so we only want to create it
  // once and store it in context for reference by components.
  const pagesizeContext = usePagesizeContextVals();

  return <PagesizeContext.Provider value={{ ...pagesizeContext }}>{props.children}</PagesizeContext.Provider>;
};

/**
 * The current context value for the window size context.
 * This value updates whenever the window is resized.
 *
 * Use this inside a {@link WindowSizeContextProvider}.
 *
 * @type number
 */
const usePagesizeContext = () => useContext(PagesizeContext);
const usePagesizeSelectorContext = (selector: any) => {
  return useContextSelector(PagesizeContext, selector);
};
export { PagesizeContextProvider, usePagesizeContext, usePagesizeSelectorContext };
